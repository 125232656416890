<template>
    <div style="padding: 20px">
        <el-form status-icon label-width="90px" label-position="left">
            <el-form-item prop="name" label="当前名称：">
                {{ user.name }}
            </el-form-item>
            <el-form-item prop="newName" label="更改名称：">
                <el-input v-model="newName" maxlength="20" placeholder="请输入名称" show-word-limit type="text" />
            </el-form-item>
        </el-form>
        <el-button type="primary" @click="editName()" style="width: 100%" ref="loginButton">保存</el-button>
    </div>
</template>

<script>
import { apiEditName } from "@/api/private/user.js";

export default {
    data() {
        return {
            user: {},
            newName: ""
        };
    },
    methods: {
        getUser() {
            if (this.$store.state.user == "") {
                //跳转到登录页面
                window.location.href = "/login?target=" + window.location.href;
            } else {
                this.user = JSON.parse(this.$store.state.user);
            }
        },
        async editName() {
            let formData = new FormData();
            this.newName = this.newName.replace(/\s*/g, "");
            if (this.newName == "") {
                this.$message({ message: "请输入新名称", type: "error" });
                return;
            }
            if (this.newName.indexOf("朝雨") >= 0) {
                this.$message({ message: "新名称不合法，请重新输入", type: "error" });
                return;
            }
            formData.append("token", this.$store.state.token);
            formData.append("new_name", this.newName);

            //调用接口
            const response = await apiEditName(formData);
            if (response.data.code === 200) {
                //浏览器保存新名称
                this.user.name = this.newName;
                //更新信息
                localStorage.setItem("user", JSON.stringify(this.user));
                sessionStorage.setItem("user", JSON.stringify(this.user));
                //通过store共享
                this.$store.dispatch("setUser", JSON.stringify(this.user));
                //调用父页面关闭layer
                window.parent.postMessage({ message: "成功修改名称", code: "200" }, "*");
            } else {
                this.$message({ message: response.data.message, type: "error" });
            }
        }
    },
    mounted() {
        //页面渲染完成调用方法获取数据
        this.getUser();
    }
};
</script>
